.csv-input {
    padding: 10px;
    display: block;
    margin: 15px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.container {
    text-align: center;
    padding: 15px;
    margin: 10px auto;
}

.csv-label {
    font-size: 20px;
    line-height: 1.42857;
    color: #000000;
    font-weight: 400;
}