.image {
    position: absolute;
    top: 70%;
    left: 50%;
    width: 129px;
    height: 61px;
    margin:-60px 0 0 -60px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

.loader {
    padding: 30px;
}

.margen{
    margin-left: 10px;
}

.content {
    width: 100px;
    height: 125px;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }